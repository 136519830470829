import React, { FC, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import { AuthContext as Oauth2Context } from "react-oauth2-code-pkce";

interface Props {
    exact?: boolean;
    path: ROUTES | string;
    children: React.ReactNode;
}

const WalletRoute: FC<Props> = (props) => {
    const { exact, path, children } = props;
    const { token } = useContext(Oauth2Context);

    console.log(token);
    return (
        <Route
            exact={exact}
            path={path}
            render={({ location }) =>
                token ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: ROUTES.SIGNIN,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

export default WalletRoute;
