import { FC, useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useIntl } from "react-intl";
import { Button } from "antd";
import { Image } from "react-bootstrap";
import { AuthContext } from "react-oauth2-code-pkce";
import TracerkeyLogo from "../../components/TracerkeyLogo/TracerkeyLogo";
import idLogo from "../../images/id_logo.png";
import useStyles from "./styles";
import AuthLayout from "./AuthLayout";
import useBreakpoint from "../../customHooks/useBreakpoint/useBreakpoint";

const SigninPage: FC<RouteComponentProps> = () => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const breakpoint = useBreakpoint();
    const { logIn, token } = useContext(AuthContext);

    console.log(token);

    return (
        <AuthLayout type="signin" title="auth.title" pageTitle="Sign In">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 32,
                    fontSize: 20,
                    fontWeight: "bold",
                }}
            >
                <Image
                    src={idLogo}
                    width="60px"
                    height="auto"
                    alt="id logo"
                    className="mr-2"
                />
                +
                <TracerkeyLogo
                    width="60px"
                    height="auto"
                    withTracerkeyText
                    style={{ marginLeft: 15 }}
                />
            </div>

            <form
                className={classes.form}
                noValidate
                style={{ marginTop: "2em" }}
            >
                <Button
                    size="large"
                    type="primary"
                    className={classes.submitBtn}
                    onClick={() => logIn(undefined, { test: "test" })}
                >
                    {formatMessage({
                        id: `auth.signin.id.two.factor.auth${
                            breakpoint === "xs" || breakpoint === "sm"
                                ? ".mobile"
                                : ""
                        }`,
                    })}
                </Button>
            </form>
        </AuthLayout>
    );
};

export default withRouter(SigninPage);
