import { DEVID } from "../constants/devIdEndpoints";
import { ROUTES } from "../constants/routes";
import config from "./config";

export const oauthConfig = {
    clientId: config?.id?.clientId as string,
    authorizationEndpoint:
        `${config?.id?.hydraUrl}${DEVID.AUTHORIZATION}` as string, // maybe change the name of the enum DEVID?
    tokenEndpoint: `${config?.id?.hydraUrl}${DEVID.TOKEN}` as string,
    redirectUri: config?.id?.redirectUri as string,
    scope: "openid offline_access",
    autoLogin: false,
    state: "this-is-a-state", // TODO: generate a random state for each auth request
    decodeToken: true,
    logoutRedirect: `${window.location.origin}${ROUTES.SIGNIN}`,
};
