import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import PublicRoute from "./PublicRoute/PublicRoute";
import SigninPage from "../../../pages/Authentification/Signin";
import Signup from "../../../pages/Authentification/Signup";
import NotFoundPage from "../../../pages/NotFoundPage/NotFoundPage";
import AccountConfirmation from "../../../pages/Authentification/AccountConfirmation";
import ConfirmationEmailSent from "../../../pages/Authentification/ConfirmationEmailSent";
import Partnership from "../../../pages/Partnership/Partnership";
import Document from "../../../pages/Document/Document";
// import IPFS from "../../../pages/IPFS/IPFS";
import WalletAuth from "../../../pages/Authentification/WalletAuth";
import Plan from "../../../pages/Plan/Plan";
import BackupRecover from "../../../pages/BackupRecover/BackupRecover";
import MyAccount from "../../../pages/Account/Account";
import Categories from "../../../pages/Categories/Categories";
import AdminDashboard from "../../../pages/Admin/Dashboard/Dashboard";
import Users from "../../../pages/Admin/Users/Users";
import StripeUsers from "../../../pages/Admin/StripeUsers/StripeUsers";
import Settings from "../../../pages/Admin/Settings/Settings";
import Dashboard from "../../../pages/Dashboard/Dashboard";
import AdminRoute from "./AdminRoute/AdminRoute";
import Logout from "../../../pages/Logout/Logout";
import WalletRoute from "./PrivateRoute/WalletAuthRoute";
const RouteStack = () => {
    return (
        <Switch>
            <Redirect exact={true} from="/" to={ROUTES.DASHBOARD} />
            <PrivateRoute exact={true} path={ROUTES.DASHBOARD}>
                <Dashboard />
            </PrivateRoute>
            <PrivateRoute exact={true} path={`${ROUTES.PARTNERSHIP}/:tab`}>
                <Partnership />
            </PrivateRoute>
            <PrivateRoute exact={true} path={ROUTES.PARTNERSHIP}>
                <Partnership />
            </PrivateRoute>
            <PrivateRoute exact={true} path={`${ROUTES.DOCUMENTS}/:tab`}>
                <Document />
            </PrivateRoute>
            <PrivateRoute exact={true} path={`${ROUTES.BACKUP_RECOVER}/:tab`}>
                <BackupRecover />
            </PrivateRoute>
            <PrivateRoute exact={true} path={ROUTES.BACKUP_RECOVER}>
                <BackupRecover />
            </PrivateRoute>
            <PrivateRoute exact={true} path={ROUTES.DOCUMENTS}>
                <Document />
            </PrivateRoute>
            {/* <PrivateRoute exact={true} path={ROUTES.IPFS}>
                <IPFS />
            </PrivateRoute> */}
            <PrivateRoute exact={true} path={ROUTES.PLAN}>
                <Plan />
            </PrivateRoute>
            <PrivateRoute exact={true} path={`${ROUTES.MY_ACCOUNT}/:tab`}>
                <MyAccount />
            </PrivateRoute>
            <PrivateRoute exact={true} path={ROUTES.SUBSCRIPTION}>
                <MyAccount />
            </PrivateRoute>
            <PrivateRoute exact={true} path={ROUTES.MY_ACCOUNT}>
                <MyAccount />
            </PrivateRoute>
            <PrivateRoute exact={true} path={ROUTES.CATEGORIES}>
                <Categories />
            </PrivateRoute>
            <PrivateRoute exact={true} path={ROUTES.LOGOUT}>
                <Logout />
            </PrivateRoute>
            <AdminRoute exact={true} path={ROUTES.ADMIN}>
                <AdminDashboard />
            </AdminRoute>
            <AdminRoute exact={true} path={ROUTES.ADMIN_DASHBOARD}>
                <AdminDashboard />
            </AdminRoute>

            <AdminRoute exact={true} path={ROUTES.ADMIN_USERS}>
                <Users />
            </AdminRoute>
            <AdminRoute exact={true} path={ROUTES.ADMIN_USERS_TX}>
                <StripeUsers />
            </AdminRoute>
            <AdminRoute exact={true} path={ROUTES.ADMIN_SETTINGS}>
                <Settings />
            </AdminRoute>
            <PublicRoute exact={true} path={ROUTES.SIGNIN}>
                <SigninPage />
            </PublicRoute>
            <PublicRoute exact={true} path={ROUTES.SIGNUP}>
                <Signup />
            </PublicRoute>
            {/* 
            <Route exact={true} path={ROUTES.TERMS_OF_SERVICE}>
                <TermsOfService />
            </Route>

            <Route exact={true} path={ROUTES.PRIVACY_POLICY}>
                <PrivacyPolicy />
            </Route> */}
            <WalletRoute exact={true} path={ROUTES.WALLET_AUTH}>
                <WalletAuth />
            </WalletRoute>
            <Route
                path={`${ROUTES.ACCOUNT_CONFIRMATION}/:confirmationToken`}
                component={AccountConfirmation}
            />
            <Route
                path={`${ROUTES.CONFIRMATION_EMAIL_SENT}/:userAddress`}
                component={ConfirmationEmailSent}
            />
            <Route component={NotFoundPage} />
        </Switch>
    );
};

export default RouteStack;
